.popUpWindow {
    position: fixed;
    height: 80vh;
    top: 10%;
    right: 25%;
    left: 25%;
    padding: 5px 0px 7px 20px;
    border-radius: 15px;
    border: 1px solid #aaa;
    background-color: rgb(250, 250, 250);
    z-index: 10 !important;
    box-shadow: 2px;
    overflow-y: auto;
    box-shadow: 0 0 0 100vmax rgba(0, 0, 0, .3);

}

.checkListContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    
}


/********************** ShowDetails ***********************/

.showDetailsItem {
    margin: 15px 40px 15px 0px;    
}



/********************** Placas - Seguros ***********************/
.checkListItem {    
    font-size: 12px;
    width: 90px;
    height: auto;   
}


.checkListLabel {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 12px;
    width: 100%
}


/********************** Equipamentos ***********************/
.equipaHeader {
    
    padding: 20px 0;
    margin: 0
}

.checkListEquipa {
    font-size: 12px;
    width: 30%
}