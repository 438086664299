.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.selectHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.addHeader {
    display: flex;
    justify-content: flex-end;
}
.headerElements {
    flex: 0, 5;
}
.divCell {
    flex: 0, 1;
    height: auto;
    margin: 4px;
    flex-direction: row;
    padding: 0 25px 0 0;
}

.configVehicleIcon {
    padding-right: 14px !important;
    width: 10px !important;
    visibility: hidden;
}

.configVehicleIcon > .confIcon {
    padding: 2px !important;
    visibility: visible;
    cursor: pointer;
}
