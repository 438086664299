.login {
    justify-content: flex-start !important;
    align-items: center;
    overflow: auto;
    position: relative;

    &:before {
        content: "";
        position: fixed;
        left: 0;
        right: 0;
        z-index: -1;
        display: block;
        background-image: url("/images/loginWallpaper.png");
        width: 100%;
        height: 100%;
        filter: brightness(400%) contrast(25%);
    }

    height: 100vh;
    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 4%;
        width: 90%;
        opacity: 0.7;
        max-width: 700px;
    }
    &__title {
        color: white;
        font-weight: 600;
        margin-bottom: 5px;
    }
    &__subtitle {
        text-align: center;
        font-weight: 500;
        &--forgotPassword {
            font-size: 0.75rem;
        }
    }
    &__text {
        &--error {
            color: darkred;
            font-size: small;
            font-family: inherit;
            font-weight: 400;
        }
    }
    &__footer {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-end;
        margin-right: 5px;
        cursor: pointer;
        color: #336699;
        font-size: 0.75rem;
    }
}

.input {
    margin: 10px 0;
}

.tst {
    color: blue !important;
    font-size: 12pt !important;
    width: 400px !important;
    /* border: 1px solid blue !important;
    border-radius: 10px !important; */
}

.webBrowsers {
    &__title {
        margin-top: 3rem;
        font-weight: 600;
        font-size: 1.1rem;
        text-align: center;
    }
    &__container {
        display: flex;
        background-color: white;
        border-radius: 5px;
        padding: 10px 0 5px 0;
        width: 280px;
        justify-content: space-evenly;
        opacity: 0.75;
    }
    &__logo {
        margin: 0;
        cursor: pointer;
    }
}
