body {
    margin: 0;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    overflow-y: scroll;
}

.jumbotron {
    width: 100%;
    padding: 80px 0 80px 0;
    border: 1px solid #ddd;
    color: white;
    background: {
        image: url("/images/busFleet4.jpg");
        size: 100% 100%;
        repeat: no-repeat;
    }
    & h1 {
        padding-left: 25px;
        font: {
            size: 2.125rem;
            family: "Roboto", "Helvetica", "Arial", sans-serif;
            weight: 400;
        }
        line-height: 1.235;
        letter-spacing: 0.00735em;
    }
    & p {
        padding-left: 25px;
    }
}

.card {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    &__container {
        display: flex;
        flex-direction: column;
        width: 9rem;
        margin-bottom: 30px;
        background-color: white;
        border-radius: 10%;
        text-decoration: none;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.08),
            0px 1px 3px 0px rgba(0, 0, 0, 0.07);
        &:not(:last-of-type) {
            margin-right: 1.5rem;
        }
        &:hover {
            background-color: #fafaff;
        }
    }

    &__image {
        text-align: center;
        padding: 1rem;
        min-height: 80px;
    }
    &__image img {
        width: 65px;
        height: 65px;
    }
    &__image:after {
        width: 75%;
        border-top: 1px solid #bbb;
        content: "";
        display: block;
        margin: 5% 0 0 10%;
    }

    &__text {
        text-align: center;
        padding: 0 1rem 0.5rem 1rem;
        color: black;
        & h3 {
            margin: 0;
            font: {
                size: 0.8rem;
                weight: 500;
            }
        }
        & p {
            margin-top: 12px;
            padding: 0 15px;
            font-size: 0.75rem;
        }
    }
}
