.rowContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 15px 0 10px;
    margin: 20px 0 25px 0;
}

.apexcharts-bar-area {
    fill: #f44336
}

.relatorioTD:last-child {
    font-weight: 500;
}

.addSpace {
    height: 30px;
}

div.apexcharts-legend:first-child {
    padding-top: 25px;
}

div.apexcharts-legend{
padding-right: 25px;
padding-left: 0;
}
