body {
    margin: 0;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    overflow-y: scroll;
}

.subtitle {
    padding-bottom: 5px;
    margin: 5px 0 10px 0;
    display: inline-flex;
    &__text {
        margin-top: 1.9px;
        font: {
            family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
            size: 13px;
            weight: 500;
        }
        text-align: left !important;
        color: #333;
    }

    &__helper {
        color: #0368a1;
        font: {
            size: 18px;
            weight: 600;
        }
        border-radius: 12px;
        margin-bottom: 2px;
        padding: 3px;
    }
}

.compartilhadoHeader {
    font-size: 11px;
    text-align: center;
    margin-top: 5px;
    color: #333;
}

.radio {
    display: inline-block;
    font-size: 0.7rem;
}

/* ************** SegurosTemplate elements ******************* */

.placasChipContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 10px 0px 10px 60px;
    & > div {
        width: 118px;
    }
}
@mixin chipMargin {
    margin: 5px 2.5px;
}
.chip {
    @include chipMargin;
    &-compartilhado {
        @include chipMargin;
        color: green !important;
    }
    &-outsider {
        @include chipMargin;
        font-weight: 500;
        color: #572154 !important;
    }
    &-irregular {
        @include chipMargin;
        color: red !important;
        font-weight: 500;
    }
}

.icon {
    padding-top: 7px;
}

.saveButton {
    color: #ffffff !important;
    margin: 20px 4px !important;
    float: right !important;
}

/* ************** Review Template elements ******************* */

.divTable {
    overflow-x: auto;
    text-align: center;
    padding: 0 15px;
    width: 100%;
    margin-bottom: 8px;
}

table {
    width: 100%;
    margin-top: 5px;
    border-collapse: collapse;
    border: none;
}

.tHeader {
    margin-top: 2%;
    color: #000;
    background-color: rgb(189, 202, 208);
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    font-weight: 500;
    border: none;
    text-align: center;
}

h6 {
    padding-left: 0px !important;
}

td.review {
    padding: 8px;
    text-align: left;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 11px;
    white-space: nowrap;
    vertical-align: middle;
    color: #000;
}

td.link2 {
    font-size: 11px;
}

th {
    padding: 5px 4px;
    text-align: left;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    background-color: rgb(240, 240, 240);
}

th.MuiTableCell-head {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
    line-height: 0.77rem;
    background-color: rgb(248, 248, 248) !important;
}

td.MuiTableCell-root {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 13.5px !important;
    padding: 6px 15px !important;
    vertical-align: middle;
    color: #000;
}

.smallFont {
    font-family: Segoe UI, Tahoma, Geneva, Verdana;
    font-size: 12px;
}

.addMargin {
    margin: 0 0 13px 20px;
}

/* ********************CLIQUE AQUI PARA <div> ************************* */

.addNewDiv {
    display: flex;
    justify-content: flex-end;
    padding: 5px 10px 0 0;
}

.addNewDiv > span {
    color: #2979db;
    font-weight: 500;
    font-size: 0.75rem;
    padding: 10px 0;
    cursor: pointer;
}

/*******************************PLACAS******************************/

.placaContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    height: 36px;
    width: 90px;
    padding: 2px 08px;
    margin: 10px;
    border: 2px solid black;
    border-radius: 5%;
    font-weight: 300;
    cursor: pointer;
}

.placaContainer:last-child {
    justify-content: flex-start;
}

.placaCity {
    font-size: 7px;
    height: 25%;
    padding: 0 0 10px 0;
}

.placaCode,
.placaCity {
    pointer-events: none;
}

.placasCaption {
    margin-right: 20px;
    width: 36%;
    justify-content: space-between !important;
    align-items: center;
}

.placasCaption > span {
    display: flex;
    align-items: center;
}
