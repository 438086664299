.app {
    background-color: #f8f8f9;
}

/* .footer {
    position: absolute;
    bottom: 1.3%;    
    border-top: 1px solid #444;    
}
 */

.radio {
    display: inline-block;
    font-size: 0.7rem;
}

.tabMenu {
    background-color: #e5eaf1;
}

h2,
h3,
h4,
h5,
h6,
p {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
    font-weight: 400;
}

h5 {
    font-size: 1.2em;
}

h6 {
    font-size: 1.1em;
    margin: 5px 0;
    font-weight: 500;
}

p {
    margin: 5px 0;
}

div.loading {
    position: fixed;
    top: 45%;
    right: 15%;
    left: 15%;
    height: 50%;
    margin-bottom: 25px;
    margin-top: 25px;
    text-align: center;
    z-index: 15;
}

tr.clickable {
    &:hover {
        background-color: #ddd;
        font-weight: 500;
    }
    cursor: pointer;
}

.flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    /* justify-content: flex-start !important; */
}

.flexStart {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start !important;
}

.flexEnd {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end !important;
    width: 100%;
}
.flex:last-child:not(:first-child) {
    justify-content: flex-start;
}

.center {
    justify-content: center !important;
    align-items: center;
}

.spaceBetween {
    justify-content: space-between;
    align-items: center;
}

.spaceBetween:last-child {
    justify-content: flex-start;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-content: center;
    padding-right: 20px;
}

.paper {
    background-color: white;
    width: auto;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px 5px;
    color: black;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.voltarDiv {
    display: flex;
    align-content: center;
    margin-top: 15px;
    padding: 5px 0 5px 10px;
    cursor: pointer;
    width: 100px;
    color: rgb(50, 65, 120);
}

.link2 {
    cursor: pointer;
    color: rgb(33, 54, 141);
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 13px;
    font-weight: 500;
}

.assessDemandButton {
    color: #df872d !important;
}

.infoLogHeader {
    padding-top: 5px;
}

.floatRight {
    display: inline-box;
    float: right;
    margin: 0px 10px 50px 0px;
    font-size: 12px;
}

.button {
    cursor: pointer;
    padding: 2px 10px 0 10px;
    border-radius: 5px;
}

.button:first-child {
    margin-left: 20px;
}

.button:hover {
    background-color: #e0e0e0;
}

.customButton {
    margin-top: 1rem !important;
}

/********************** Material Table **********************/

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft {
    font-size: 8.5pt !important;
    font-family: Geneva, Verdana, sans-serif !important;
}

.MuiTableRow-root {
    height: 15px !important;
}

.MuiTypography-root.MuiTypography-h6 {
    font-size: 12pt;
}

/*******************Material input Helper **********************/
.MuiFormHelperText-root.MuiFormHelperText-contained.makeStyles-helperText-23 {
    line-height: 1 !important;
    margin: 1.5px 0 !important;
}

.pointer {
    cursor: pointer;
    margin-left: 0 !important;
}

/* *******************MAIN HEADER NAV MENU****************************** */

.adminLink {
    margin: 10px 0 0 9px;
}

.iconHeaderLink {
    margin: 0;
    border-radius: 50%;
    padding: 10%;
    border: 1px solid #ddd;
}

.avisosLink {
    margin: 4px 0 0 5px;
}
