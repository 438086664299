.container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1.5rem;
}

.tableContainer {
    width: 80%;
}

th > span .MuiIconButton-colorSecondary {
    background-color: red !important;
}

.avisosHeader {
    display: flex;
    margin-left: 10%;
    justify-content: flex-end;
    height: 50px;
    width: 80%;
    margin-right: 7rem;
}

.avisosHeaderAdmin {
    display: flex;
    margin-left: 10%;
    justify-content: space-between;
    height: 50px;
    width: 80%;
    margin-right: 7rem;
}
