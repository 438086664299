.radio {
    &__title {
        text-align: center;
        color: #555;
    }
    &__legend {
        font-size: 9pt;
        font-weight: 500;
    }
}

.dischargedContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0 0 7%;
}

.reactivate {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.obs {
    display: flex;
    flex-direction: column;
    margin: 1% 7%;
    width: 100%;
    &__textArea {
        padding: 9px;
        font-size: 0.65rem;
        font-family: inherit;
    }
    &__label {
        margin: 5px 0;
        font-size: 0.7rem;
        font-weight: 500;
        font-family: inherit;
    }
}

.searchDischarged {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-right: 20px;
    &__header {
        width: 100%;
    }
    & > div:first-child {
        grid-column-start: 2;
        display: flex;
        justify-content: center;
    }
    & > div:last-child {
        margin: 11px 0 0 auto;
    }
}
.downloadXls {
    font-size: 0.7rem;
    margin: 0 0 0 10px;
    font-weight: 500;
    padding: 3px;
    cursor: pointer;
    display: flex;

    &::before {
        content: "Todos os veículos";
        color: rgb(6, 70, 6);
        margin-top: 3px;
    }
    &__icon {
        width: 22px;
        height: 20px;
        margin-left: 5px;
    }
}
