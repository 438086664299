.aviso {
    &__body {
        font-size: 0.78rem;
        padding: 2.5%;
        border: 1px solid #000;
        text-align: justify;
        text-justify: inter-word;
        border: 1px solid #bbbbbb;
        border-radius: 1%;
    }
    &__title {
        font-size: 0.8rem;
        font-weight: 500;
    }
    &__table {
        width: 100%;
        max-width: 700px;
        margin-top: 5px;
        border-collapse: collapse;
    }
    &__th {
        margin-top: 2%;
        color: #000;
        background-color: rgb(189, 202, 208);
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        font-size: 0.7rem;
        font-weight: 500;
        border: none;
        text-align: center;
    }
    &__td {
        border: 0.5px solid #ddd;
        padding: 8px;
        text-align: left;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
        font-size: 0.7rem;
        font-weight: 400;
        text-align: center;
        color: #000;
    }
}
