body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

div {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

div.selectEmpresa {
  width: 100%;
  display: flex;
  justify-content: center;
  min-width: 600px;  
}

div.item {
  padding: 1px 0 17px 0
}

h3 {
  font-family: Segoe UI, 'Helvetica', 'Verdana', 'sans-serif';
  font-size: 16px;
  color: #333;
  font-weight: 500;
  line-height: 1;
  margin: 5px 0
}

.selectedEmpresa {
  margin: 5px 0 0 0;
  font-family: inherit;
}

span.selectedEmpresa {
  margin: 10px 0 15px 0;
}

input.selectEmpresa {
  min-width: 415px;
  text-align: center;
  font-size: 13px;
  font-family: inherit;
  padding: 2px;
  margin: 3px 0;
  border: none;
  border-bottom: 1px solid #ccc;
}

input.selectEmpresa:focus {
  border: none;
  border-bottom: 2px solid #0000B9;
  outline: 0;
  padding-bottom: 1px;
}

.MuiIcon-colorAction {
  color: rgb(58, 164, 33) !important;
}

p.fileInput {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  margin-top: 0;
}

.MuiStepLabel-label {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 12px !important;
}

.MuiStepper-horizontal {
  padding: 5px 24px !important;
}

.MuiBreadcrumbs-ol {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

/* ************************ FILES - DRAG AND DROPBOX */

.dropBox {
  font-size: 12px;
  color: grey;
  padding: 6px 30px;
  margin-right: 5px;
  background-color: #eee;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
}

.multipleDropBox {
  font-size: 12px;
  color: grey;
  background-color: #eee;
  border-radius: 5px;
  padding: 3px 0 11px 8px;
  cursor: pointer;
  align-items: center;
}

.dropDiv {
  padding: 3px 0 11px 8px;
  margin: 0 10px 10px 0;
  min-width: 390.5px
}
.dropDiv:last-child {
  margin-right: 0;
}

.multipleDropBox:last-child {
  margin-right: 5px;
}

.cunt {
  margin: 5px 0 7px 84.58%;
  width: 100%
}
.cc {
  width: 100%
}

.fileAttached {
  background-color: #78B4E6;
  color: white
}