@mixin divLine {
    display: flex;
    margin: 7mm 0 3mm 0;
    width: 100%;
    padding: 0 2mm 0 6mm;
}

.A4 {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
    background: white;
    width: 20cm;
    height: 28cm;
    margin: 0 auto;
    padding: 0 6mm;
    margin-bottom: 0.5cm;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
    overflow-y: hidden;
    box-sizing: border-box;
    z-index: 2;
}

.box {
    border: 1px solid #333;
    border-radius: 3mm;
    position: relative;
    margin-bottom: 2.2mm;
}

.firstBox {
    border: 1px solid #333;
    border-radius: 3mm;
    padding-top: 0;
    margin-bottom: 3mm;
}

.boxTitle {
    position: absolute;
    top: 1mm;
    left: 2mm;
    font-size: 9pt;
    font-weight: 600;
}

.divLine {
    @include divLine();
}

.firstDivLine {
    margin: 3mm !important;
    top: 0;
}

.equipa {
    font-size: 7.5pt;
}

@media print {
    html,
    body {
        margin: 0 !important;
        padding: 0 !important;
    }
    .A4 {
        box-shadow: none;
        margin: 0 !important;
        padding: 0 !important;
        width: 200mm;
        height: 28cm;
        display: block !important;
        overflow-x: hidden;
        page-break-after: avoid;
    }
    .divLine {
        @include divLine();
    }
    .certFooter {
        page-break-after: avoid;
    }
    .page-break {
        display: block;
        page-break-inside: avoid;
        page-break-after: avoid;
    }
    nav,
    .MuiToolbar-root,
    .MuiTabs-flexContainer,
    .noPrint,
    .MuiToolbar-root,
    .MuiToolbar-regular,
    .MuiToolbar-gutters,
    .noprint,
    .printButton {
        display: none !important;
    }
}

.certificate {
    border-radius: 0% !important;
    padding-top: 1mm !important;
    height: 15px !important;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 11px !important;
    font-weight: 500 !important;
    vertical-align: middle;
    text-align: center !important;
}

.MuiInputBase-multiline.MuiInput-multiline,
.MuiOutlinedInput-multiline {
    height: auto;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
    text-align: justify !important;
}

.printButton {
    cursor: pointer;
    color: rgb(161, 161, 180);
    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: 17px;
    left: 1365px;
}

.link {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}

.certHeader {
    &__container {
        margin: 0 0 2.5mm 0;
        border: 1px #333 solid;
        border-radius: 15px;
        display: grid;
        grid-template-columns: 12% auto;
        align-items: center;
    }
    &__brasao {
        display: flex;
        justify-content: center;
        background-image: "/images/brasao.png";
    }
    &__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-items: center;
    }
    &__text > p {
        font-size: 7pt;
        margin: 0 75px 0 0 !important;
        line-height: 0.9rem;
        text-transform: uppercase;
    }
    &__text > h2 {
        margin: 10px 75px 8px 0 !important;
        font-weight: 700;
    }
    &__img {
        width: 85px;
        height: 75px;
        margin: 3mm 0 1.5mm 5mm;
    }
}

.certFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    &__title {
        font: {
            weight: 700;
            size: 20pt;
        }
        margin: 2mm 0 0mm 0;
        letter-spacing: 2.2mm;
        text-shadow: 2px 2px #aaa;
    }
    &__text {
        font-size: 8.5pt;
    }
}
