.homeMainPost {
    width: 100%;
    margin-bottom: 30px;
    padding: 54px 0 54px 15px;
    color: white;
    position: relative;
    z-index: 1;
    font-size: 50px;
    line-height: 100vh;
}

.homeMainPost:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-image: url('/images/bus_wallpaper.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    content: '';
    filter: brightness(75%);
    z-index: -1;
}

.homeMainPost h1 {
    font-size: 3rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.167;
    letter-spacing: 0em;
}

.homeMainPost h2 {
    font-size: 1.5rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.334;
    letter-spacing: 0em;
}

.homeCardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;    
}

.homeCard {
    display: flex;
    flex-wrap: wrap;    
    width: 270px;
    background-color: white;
    margin-bottom: 30px;    
    border-radius: 4%;
    color: black;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
    height:160px
}

.homeCard:hover {    
    cursor: pointer;
    filter: brightness(99%);
}

.homeCardText {
    width: 45%;    
    text-decoration: none;
    padding: 10px 0 10px 10px !important;
}

h6.homeCardText {
    text-decoration: none;
    margin-bottom: auto;
    margin-top: 0px;    
}

p.homeCardText {    
    width: 100%;    
    font-size: 8pt;        
    text-decoration: none;
    margin: 0;
}

div.homeImage {    
    width: 55%;      
    text-align: center;    
}

img.homeImage {
    width: 150px;
    height: 160px;    
    border-radius: 4%;
    padding-right: 1px;
}

.homeCardLink {
    font-size: 9pt; 
    color: rgb(62, 62, 182);
    padding-left: 10px;
}